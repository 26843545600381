import React, { useState, useEffect } from "react";
import { IoCloudUpload } from "react-icons/io5";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { useNavigate, useParams } from "react-router-dom";
import { fetchUsers } from "../../api/apiUser";
import { fetchProducts } from "../../api/apiProduct";
import { fetchComplaintCategories } from "../../api/apiCategoryComplaint";
import { fetchTypeComplaints } from "../../api/apiTypeComplaint";
import axios from "axios";

const formatDate = (dateString) => {
  const reportDate = new Date(dateString);
  const day = reportDate.getDate().toString().padStart(2, "0"); // Menambahkan nol di depan jika kurang dari 10
  const month = (reportDate.getMonth() + 1).toString().padStart(2, "0"); // Menambahkan nol di depan jika kurang dari 10
  const year = reportDate.getFullYear();
  return `${year}-${month}-${day}`;
};

const FormEditComplaintWithItem = () => {
  const [reportDate, setReportDate] = useState("");
  const [complaintName, setComplaintName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [SelectedUserDelegate, setSelectedUserDelegate] = useState("");
  const [userDelegates, setUserDelegates] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [typesRes, setTypesRes] = useState([]);
  const [items, setItems] = useState([
    {
      productId: "",
      quantity: "",
      notes: "",
      complaintCategoryId: "",
      photoComplaint: "",
      photoProductionProduct: "",
    },
  ]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [msg, setMsg] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [details, setDetails] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchResources();
    fetchComplaintDetails();
  }, []);

  const fetchResources = async () => {
    try {
      const [usersRes, productsRes, categoriesRes, typesRes] =
        await Promise.all([
          fetchUsers(),
          fetchProducts(),
          fetchComplaintCategories(),
          fetchTypeComplaints(),
        ]);
      setUserDelegates(usersRes);
      setProducts(productsRes);
      setCategories(categoriesRes);
      setTypesRes(typesRes);
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  };

  const fetchComplaintDetails = async () => {
    try {
      const response = await axios.get(
        `https://complaint.favori.web.id/complaintwithitem/${id}`
      );
      const complaintData = response.data;
      setReportDate(formatDate(complaintData.complaint.reportDate));
      setComplaintName(complaintData.complaint.complaintName);
      setPhoneNumber(complaintData.complaint.phoneNumber);
      setAddress(complaintData.complaint.address);
      setSelectedType(complaintData.complaint.complaintTypeId);
      setSelectedUserDelegate(complaintData.complaint.userIdDelegate);
      setDescription(complaintData.complaint.description);
      setItems(complaintData.items);
    } catch (error) {
      console.error("Error fetching complaint details:", error);
    }
  };

  const saveComplaint = async (e) => {
    e.preventDefault();

    // Validasi input sebelum mengirimkan
    if (!complaintName || !phoneNumber || !description) {
      alert("Please fill in all required fields");
      return;
    }

    const formData = new FormData();
    formData.append("reportDate", reportDate);
    formData.append("complaintName", complaintName);
    formData.append("phoneNumber", phoneNumber);
    formData.append("address", address);
    formData.append("complaintTypeId", selectedType);
    formData.append("userIdDelegate", SelectedUserDelegate);
    formData.append("description", description);
    formData.append("status", 0);

    // Tambahkan data untuk setiap item
    items.forEach((item, index) => {
      formData.append(`items[${index}][productId]`, item.productId);
      formData.append(`items[${index}][quantity]`, item.quantity);
      formData.append(`items[${index}][notes]`, item.notes);
      formData.append(
        `items[${index}][complaintCategoryId]`,
        item.selectedCategory
      );
    });

    try {
      await axios.patch(
        `https://complaint.favori.web.id/complaintwithitem/${id}`,
        formData,
        {}
      );
      setMsg("Complaint updated successfully");
      navigate("/complaints");
    } catch (error) {
      console.error("Error saving complaint:", error);
      alert("Failed to update complaint");
    }
  };

  const handleItemChange = (index, key, value) => {
    const newItems = [...items];
    newItems[index][key] = value;
    setItems(newItems);
  };

  const addNewItemRow = () => {
    setItems([
      ...items,
      {
        productId: "",
        quantity: "",
        notes: "",
        complaintCategoryId: "",
        photoProductionProduct: "",
        photoComplaint: "",
      },
    ]);
  };

  const removeItemRow = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const printComplaintDetails = () => {
    // Create a string representation of complaint details
    const detailsString = `
      Report Date: ${reportDate}
      Complaint Name: ${complaintName}
      Phone Number: ${phoneNumber}
      Address: ${address}
      Selected Type: ${selectedType}
      Selected User Delegate: ${SelectedUserDelegate}
      Description: ${description}
      Items:
      ${items
        .map(
          (item, index) => `
        Item ${index + 1}:
        Product Id: ${item.productId}
        Quantity: ${item.quantity}
        Notes: ${item.notes}
        Complaint Category Id: ${item.complaintCategoryId}
      `
        )
        .join("\n")}
    `;
    setDetails(detailsString);
    setIsModalOpen(true);
  };

  return (
    <div>
      <TitleBar titles={["Admin", "Complaints"]} />
      <HeroBar title="Edit Complaint" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <form onSubmit={saveComplaint}>
                  <p className="has-text-centered">{msg}</p>
                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <label className="label">Sumber</label>
                        <div className="select is-hovered is-fullwidth">
                          <select
                            value={selectedType}
                            onChange={(e) => setSelectedType(e.target.value)}>
                            <option value="">Pilih Sumber Laporan...</option>
                            {typesRes.map(({ id, name }) => (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Nama Sumber</label>
                        <div className="control">
                          <input
                            type="text"
                            className="input"
                            placeholder="Nama Pelanggan/Gudang/Outlet/Pameran"
                            value={complaintName}
                            onChange={(e) => setComplaintName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Alamat</label>
                        <div className="control">
                          <input
                            type="text"
                            className="input"
                            placeholder="Alamat bisa dikosongkan apabila bukan pelanggan"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label">Tanggal Laporan</label>
                        <div className="control">
                          <input
                            type="date"
                            className="input"
                            value={reportDate}
                            onChange={(e) => setReportDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Nomor Telepon</label>
                        <div className="control">
                          <input
                            type="text"
                            className="input"
                            placeholder="0823xxxxxx"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">User Delegasi</label>
                        <div className="control">
                          <div className="select is-hovered is-fullwidth">
                            <select
                              value={SelectedUserDelegate}
                              onChange={(e) =>
                                setSelectedUserDelegate(e.target.value)
                              }>
                              <option value="">Pilih User Delegasi...</option>
                              {userDelegates.map(({ id, name }) => (
                                <option
                                  key={id}
                                  value={id}
                                  selected={id === SelectedUserDelegate}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Produk</th>
                        <th>Jumlah</th>
                        <th>Kategori</th>
                        <th>Catatan</th>
                        <th>Foto Kode Produksi</th>
                        <th>Foto Produk</th>
                        <th>
                          <button
                            type="button"
                            className="button is-success"
                            onClick={addNewItemRow}>
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map(
                        (
                          { productId, quantity, notes, complaintCategoryId },
                          index
                        ) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <div className="select is-small is-hovered is-fullwidth">
                                    <select
                                      value={productId}
                                      onChange={(e) =>
                                        handleItemChange(
                                          index,
                                          "productId",
                                          e.target.value
                                        )
                                      }>
                                      <option value="">Pilih produk...</option>
                                      {products.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                          {name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <input
                                    type="text"
                                    className="input is-small"
                                    name="quantity"
                                    value={quantity}
                                    onChange={(e) =>
                                      handleItemChange(
                                        index,
                                        "quantity",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <div className="select is-small is-hovered is-fullwidth">
                                    <select
                                      value={complaintCategoryId}
                                      onChange={(e) =>
                                        handleItemChange(
                                          index,
                                          "complaintCategoryId",
                                          e.target.value
                                        )
                                      }>
                                      <option value="">
                                        Pilih kategori...
                                      </option>
                                      {categories.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                          {name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <input
                                    type="text"
                                    className="input is-small"
                                    name="notes"
                                    value={notes}
                                    onChange={(e) =>
                                      handleItemChange(
                                        index,
                                        "notes",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="control">
                                <div className="file is-small">
                                  <label className="file-label">
                                    <input
                                      className="file-input"
                                      type="file"
                                      name="photoProductionProduct"
                                    />
                                    <span className="file-cta">
                                      <span className="file-icon">
                                        <IoCloudUpload />
                                      </span>
                                      <span className="file-label">
                                        Pilih file…
                                      </span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="control">
                                <div className="file is-small">
                                  <label className="file-label">
                                    <input
                                      className="file-input"
                                      type="file"
                                      name="photoComplaint"
                                    />
                                    <span className="file-cta">
                                      <span className="file-icon">
                                        <IoCloudUpload />
                                      </span>
                                      <span className="file-label">
                                        Pilih file…
                                      </span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                type="button"
                                className="button is-danger is-small"
                                onClick={() => removeItemRow(index)}>
                                Hapus
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  <div className="field">
                    <label className="label">Keterangan Tambahan</label>
                    <div className="control">
                      <textarea
                        className="textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field is-grouped">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Simpan
                      </button>
                    </div>
                    <div className="control">
                      <button
                        type="button"
                        className="button is-primary"
                        onClick={printComplaintDetails}>
                        Print
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isModalOpen && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Complaint Details</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => setIsModalOpen(false)}></button>
            </header>
            <section className="modal-card-body">
              <pre>{details}</pre>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormEditComplaintWithItem;
