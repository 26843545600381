import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { getComplaints, deleteComplaint } from "../../api/apiComplaint";

// Fungsi untuk mengubah format tanggal dan waktu
const formatDate = (dateString) => {
  const reportDate = new Date(dateString);
  const day = reportDate.getDate();
  const month = reportDate.getMonth() + 1;
  const year = reportDate.getFullYear();
  const hours = reportDate.getHours();
  const minutes = reportDate.getMinutes();
  const seconds = reportDate.getSeconds();
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};
const ComplaintList = () => {
  const { user } = useSelector((state) => state.auth);
  const STATUS = {
    0: "Waiting Approve",
    1: "Approve PIC",
    2: "Approve Manager",
    3: "Selesai",
    99: "Ditolak",
  };
  const STATUS_BADGE_CLASSES = {
    0: "is-warning",
    1: "is-info",
    2: "is-link",
    3: "is-success",
    99: "is-danger",
  };

  const [selectedComplaint, setSelectedComplaint] = useState(null);

  const handleViewComplaint = (complaint) => {
    setSelectedComplaint(complaint);
  };

  const handleClosePopup = () => {
    setSelectedComplaint(null);
  };
  const [complaints, setComplaints] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async (page) => {
    const { complaints: complaintsData, total_pages: total } =
      await getComplaints(page);
    setComplaints(complaintsData);
    setTotalPages(total);
  };

  const confirmDeleteComplaint = async (complaintId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      const success = await deleteComplaint(complaintId);
      if (success) {
        fetchData(currentPage);
        Swal.fire("Deleted!", "Complaint has been deleted.", "success");
      } else {
        Swal.fire("Error!", "Failed to delete complaint.", "error");
      }
    }
  };

  return (
    <div>
      <TitleBar titles={["Admin", "Complaints"]} />
      <HeroBar title="Complaints" />
      <section className="section is-main-section">
        <Link to="/complaints/add" className="button is-primary mb-2">
          Add Complaints
        </Link>
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-account"></i>
              </span>
              Complaints List
            </p>
            <a href="!#" className="card-header-icon">
              <span className="icon">
                <i className="mdi mdi-reload"></i>
              </span>
            </a>
          </header>

          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>No</th>
                <th>Date Create</th>
                <th>Type</th>
                <th>Nama Pelapor</th>
                <th>No. Telepon</th>
                <th>Alamat</th>
                <th>Status</th>
                <th>Pembuat</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {complaints.map((complaint, index) => (
                <tr key={complaint.uuid}>
                  <td>{index + 1}</td>
                  <td>{formatDate(complaint.reportDate)}</td>
                  <td>{complaint.complaint_type.name}</td>
                  <td>{complaint.complaintName}</td>
                  <td>{complaint.phoneNumber}</td>
                  <td>{complaint.address}</td>
                  <td>
                    <span
                      className={`tag ${
                        STATUS_BADGE_CLASSES[complaint.status]
                      }`}>
                      {STATUS[complaint.status]}
                    </span>
                  </td>
                  <td>{complaint.user.name}</td>
                  <td>
                    {/* Tombol View */}
                    <button
                      onClick={() => handleViewComplaint(complaint)}
                      className="button is-small is-primary mr-2">
                      View
                    </button>
                    {complaint.status === 0 && ( // Tombol Edit hanya ditampilkan jika status adalah 0
                      <Link
                        to={`/complaints/edit/${complaint.uuid}`}
                        className="button is-small is-info">
                        Edit
                      </Link>
                    )}
                    {user && user.role === "admin" && (
                      <button
                        onClick={() => confirmDeleteComplaint(complaint.uuid)}
                        className="button is-small is-danger">
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Popup untuk melihat detail keluhan */}
          {selectedComplaint && (
            <div className="modal is-active">
              <div
                className="modal-background"
                onClick={handleClosePopup}></div>
              <div className="modal-content">
                <div className="box">
                  <h3 className="title is-3">Complaint Detail</h3>
                  <p>
                    <strong>Complaint Name:</strong>{" "}
                    {selectedComplaint.complaintName}
                  </p>
                  <p>
                    <strong>Phone Number:</strong>{" "}
                    {selectedComplaint.phoneNumber}
                  </p>
                  <p>
                    <strong>Address:</strong> {selectedComplaint.address}
                  </p>
                  {/* Menampilkan status keluhan dengan warna badge */}
                  <p>
                    <strong>Status:</strong>
                    <span
                      className={`tag ${
                        STATUS_BADGE_CLASSES[selectedComplaint.status]
                      } has-text-white ml-2`}>
                      {STATUS[selectedComplaint.status]}
                    </span>
                  </p>
                  {/* Menampilkan tombol close */}
                  <button className="button is-link" onClick={handleClosePopup}>
                    Close
                  </button>
                </div>
              </div>
              <button
                className="modal-close is-large"
                aria-label="close"
                onClick={handleClosePopup}></button>
            </div>
          )}
        </div>
        <nav className="pagination" role="navigation" aria-label="pagination">
          <button
            className="pagination-previous"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}>
            Previous
          </button>
          <button
            className="pagination-next"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}>
            Next page
          </button>
          <ul className="pagination-list">
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index}>
                <button
                  className={`pagination-link ${
                    currentPage === index + 1 ? "is-current" : ""
                  }`}
                  aria-label={`Goto page ${index + 1}`}
                  onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default ComplaintList;
