import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";

const FormAddCustomer = () => {
  const [namaLengkap, setNamaLengkap] = useState("");
  const [noTelp, setNoTelp] = useState("");
  const [alamat, setAlamat] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const saveCustomer = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://complaint.favori.web.id/customers", {
        nama_lengkap: namaLengkap,
        no_telp: noTelp,
        alamat: alamat,
      });
      navigate("/customers");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <TitleBar titles={["Admin", "Customers"]} />
      <HeroBar title="Add Customers" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <form onSubmit={saveCustomer}>
                  <p className="has-text-centered">{msg}</p>
                  <div className="field">
                    <label className="label">Nama Lengkap</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={namaLengkap}
                        onChange={(e) => setNamaLengkap(e.target.value)}
                        placeholder="Nama Lengkap"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">No. Telepon</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={noTelp}
                        onChange={(e) => setNoTelp(e.target.value)}
                        placeholder="No. Telepon"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Alamat</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={alamat}
                        onChange={(e) => setAlamat(e.target.value)}
                        placeholder="Alamat"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormAddCustomer;
