import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchCustomersCount = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/customers`);
    return response.data.total_count;
  } catch (error) {
    console.error("Error fetching customers count:", error);
    return 0;
  }
};

export const fetchCustomers = async (page) => {
  try {
    const response = await axios.get(`${baseApiUrl}/customers?page=${page}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching customers:", error);
    throw error;
  }
};

export const deleteCustomer = async (customerId) => {
  try {
    await axios.delete(`${baseApiUrl}/customers/${customerId}`);
  } catch (error) {
    console.error("Error deleting customer:", error);
    throw error;
  }
};
