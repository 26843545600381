import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchProductsCount = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/products`);
    return response.data.total_count;
  } catch (error) {
    console.error("Error fetching products count:", error);
    return 0;
  }
};

export const fetchProducts = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/products`);
    return response.data.products;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
