import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";

const EditForm = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getUserDetails(id);
  }, [id]);

  const getUserDetails = async (userId) => {
    try {
      const response = await axios.get(
        `https://complaint.favori.web.id/users/${userId}`
      );
      const { name, email, role } = response.data;
      setName(name);
      setEmail(email);
      setRole(role);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const updateUser = async () => {
    try {
      await axios.put(`https://complaint.favori.web.id/users/${id}`, {
        name,
        email,
        role,
      });
      Swal.fire("Success!", "User updated successfully.", "success");
      navigate("/users");
    } catch (error) {
      console.error("Error updating user:", error);
      Swal.fire("Error!", "Failed to update user.", "error");
    }
  };

  return (
    <>
      <TitleBar titles={["Admin", "Users"]} />
      <HeroBar title="Edit Users" />
      <section className="section is-main-section">
        <div className="card is-shadowless">
          <div className="card-content">
            <div className="content">
              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Role</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={role}
                      onChange={(e) => setRole(e.target.value)}>
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button onClick={updateUser} className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditForm;
