import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IoLogOut,
  IoOptions,
  IoMail,
  IoPersonCircle,
  IoReorderThree,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [isActive] = useState(false);

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <nav id="navbar-main" className="navbar is-fixed-top">
      <div className="navbar-brand">
        <a className="navbar-item is-hidden-desktop jb-aside-mobile-toggle">
          <span className="icon">
            <IoReorderThree></IoReorderThree>
          </span>
        </a>
        <div className="navbar-item has-control">
          <div className="control">
            <input placeholder="Search everywhere..." className="input" />
          </div>
        </div>
      </div>
      <div className="navbar-brand is-right">
        <a
          className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
          data-target="navbar-menu">
          <span className="icon">
            <i className="mdi mdi-dots-vertical"></i>
          </span>
        </a>
      </div>
      <div
        className={`navbar-menu fadeIn animated faster ${
          isActive ? "is-active" : ""
        }`}
        id="navbar-menu">
        <div className="navbar-end">
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <a className="navbar-link is-arrowless">
              <div className="is-user-avatar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-user-circle"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                  <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                  <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
                </svg>
              </div>
              <div className="is-user-name">
                <span>{user && user.name}</span>
              </div>
              <span className="icon">
                <i className="mdi mdi-chevron-down"></i>
              </span>
            </a>
            <div className="navbar-dropdown">
              <a href="profile.html" className="navbar-item">
                <span className="icon">
                  <IoPersonCircle></IoPersonCircle>
                </span>
                <span>My Profile</span>
              </a>
              <a className="navbar-item">
                <span className="icon">
                  <IoOptions></IoOptions>
                </span>
                <span>Settings</span>
              </a>
              <a className="navbar-item">
                <span className="icon">
                  <IoMail></IoMail>
                </span>
                <span>Messages</span>
              </a>
              <hr className="navbar-divider" />
              <a className="navbar-item" onClick={logout}>
                <span className="icon">
                  <IoLogOut></IoLogOut>
                </span>
                <span>Log Out</span>
              </a>
            </div>
          </div>
          <a className="navbar-item is-desktop-icon-only" onClick={logout}>
            <span className="icon">
              <IoLogOut></IoLogOut>
            </span>
            <span>Log out</span>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
