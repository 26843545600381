import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchUsers = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/users`);
    return response.data.users;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};
