import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { fetchCustomers, deleteCustomer } from "../../api/apiCustomer";

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getCustomers(currentPage);
  }, [currentPage]);

  const getCustomers = async (page) => {
    try {
      const { customers: customersData, total_pages: total } =
        await fetchCustomers(page);
      setCustomers(customersData);
      setTotalPages(total);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const confirmDeleteCustomer = async (customerId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      deleteCustomer(customerId)
        .then(() => {
          getCustomers(currentPage);
          Swal.fire("Deleted!", "Customer has been deleted.", "success");
        })
        .catch((error) => {
          console.error("Error deleting customer:", error);
          Swal.fire("Error!", "Failed to delete customer.", "error");
        });
    }
  };

  return (
    <div>
      <TitleBar titles={["Admin", "Customers"]} />
      <HeroBar title="Customers" />
      <section className="section is-main-section">
        <Link to="/customers/add" className="button is-primary mb-2">
          Add Customers
        </Link>
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-account"></i>
              </span>
              Customers List
            </p>
            <a href="!#" className="card-header-icon">
              <span className="icon">
                <i className="mdi mdi-reload"></i>
              </span>
            </a>
          </header>

          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Lengkap</th>
                <th>No. Telepon</th>
                <th>Alamat</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => (
                <tr key={customer.uuid}>
                  <td>{index + 1}</td>
                  <td>{customer.nama_lengkap}</td>
                  <td>{customer.no_telp}</td>
                  <td>{customer.alamat}</td>
                  <td>
                    <Link
                      to={`/customers/edit/${customer.uuid}`}
                      className="button is-small is-info">
                      Edit
                    </Link>
                    <button
                      onClick={() => confirmDeleteCustomer(customer.uuid)}
                      className="button is-small is-danger">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav className="pagination" role="navigation" aria-label="pagination">
          <button
            className="pagination-previous"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}>
            Previous
          </button>
          <button
            className="pagination-next"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}>
            Next page
          </button>
          <ul className="pagination-list">
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index}>
                <button
                  className={`pagination-link ${
                    currentPage === index + 1 ? "is-current" : ""
                  }`}
                  aria-label={`Goto page ${index + 1}`}
                  onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default CustomerList;
