import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";

const Userlist = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getUsers(currentPage);
  }, [currentPage]);

  const getUsers = async (page) => {
    try {
      const response = await axios.get(
        `https://complaint.favori.web.id/users?page=${page}`
      );
      const { users: userData, total_pages: total } = response.data;
      setUsers(userData);
      setTotalPages(total);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const confirmDeleteUser = async (userId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      deleteUser(userId);
    }
  };

  const deleteUser = async (userId) => {
    try {
      await axios.delete(`https://complaint.favori.web.id/users/${userId}`);
      getUsers(currentPage);
      Swal.fire("Deleted!", "User has been deleted.", "success");
    } catch (error) {
      console.error("Error deleting user:", error);
      Swal.fire("Error!", "Failed to delete user.", "error");
    }
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <TitleBar titles={["Admin", "Users"]} />
      <HeroBar title="Users" />
      <section className="section is-main-section">
        <Link to="/users/add" className="button is-primary mb-2">
          Add New
        </Link>
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-tag"></i>
              </span>
              Users List
            </p>
            <a href="!#" className="card-header-icon">
              <span className="icon">
                <i className="mdi mdi-reload"></i>
              </span>
            </a>
          </header>

          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user.uuid}>
                  <td>{index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    <Link
                      to={`/users/edit/${user.uuid}`}
                      className="button is-small is-info">
                      Edit
                    </Link>
                    <button
                      onClick={() => confirmDeleteUser(user.uuid)}
                      className="button is-small is-danger">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav className="pagination" role="navigation" aria-label="pagination">
          <button
            className="pagination-previous"
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1}>
            Previous
          </button>
          <button
            className="pagination-next"
            onClick={() => goToPage(currentPage + 1)}
            disabled={currentPage === totalPages}>
            Next page
          </button>
          <ul className="pagination-list">
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index}>
                <button
                  className={`pagination-link ${
                    currentPage === index + 1 ? "is-current" : ""
                  }`}
                  aria-label={`Goto page ${index + 1}`}
                  onClick={() => goToPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default Userlist;
