import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./components/Login";
import Users from "./pages/users/Users";
import Products from "./pages/products/Products";
import AddUser from "./pages/users/AddUser";
import EditUser from "./pages/users/EditUser";
import AddProduct from "./pages/products/AddProduct";
import EditProduct from "./pages/products/EditProduct";
import Customers from "./pages/customers/Customers";
import AddCustomer from "./pages/customers/AddCustomer";
import EditCustomer from "./pages/customers/EditCustomer";
import AddComplaintWithItemPage from "./pages/complaint/AddComplaint";
import Complaints from "./pages/complaint/Complaints";
import EditComplaint from "./pages/complaint/EditComplaint";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/edit/:id" element={<EditUser />} />

          <Route path="/products" element={<Products />} />
          <Route path="/products/add" element={<AddProduct />} />
          <Route path="/products/edit/:id" element={<EditProduct />} />

          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/add" element={<AddCustomer />} />
          <Route path="/customers/edit/:id" element={<EditCustomer />} />

          <Route path="/complaints" element={<Complaints />} />
          <Route
            path="/complaints/add"
            element={<AddComplaintWithItemPage />}
          />
          <Route path="/complaints/edit/:id" element={<EditComplaint />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
