import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { useSelector } from "react-redux";

const ProductList = () => {
  const { user } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getProducts(currentPage);
  }, [currentPage]);

  const getProducts = async (page) => {
    try {
      const response = await axios.get(
        `https://complaint.favori.web.id/products?page=${page}`
      );
      const { products: productsData, total_pages: total } = response.data;
      setProducts(productsData);
      setTotalPages(total);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const confirmDeleteProduct = async (productId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      deleteProduct(productId);
    }
  };

  const deleteProduct = async (productId) => {
    try {
      await axios.delete(
        `https://complaint.favori.web.id/products/${productId}`
      );
      getProducts(currentPage);
      Swal.fire("Deleted!", "Product has been deleted.", "success");
    } catch (error) {
      console.error("Error deleting product:", error);
      Swal.fire("Error!", "Failed to delete product.", "error");
    }
  };

  const renderProductNumber = (index) => {
    return (currentPage - 1) * 10 + index + 1;
  };

  return (
    <div>
      <TitleBar titles={["Admin", "Products"]} />
      <HeroBar title="Products" />
      <section className="section is-main-section">
        <Link to="/products/add" className="button is-primary mb-2">
          Add Products
        </Link>
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-tag"></i>
              </span>
              Products List
            </p>
            <a href="#!" className="card-header-icon">
              <span className="icon">
                <i className="mdi mdi-reload"></i>
              </span>
            </a>
          </header>

          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>No</th>
                <th>SKU</th>
                <th>Name</th>
                <th>Price</th>
                <th>Owner</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={product.uuid}>
                  <td>{renderProductNumber(index)}</td>
                  <td>{product.sku}</td>
                  <td>{product.name}</td>
                  <td>{product.price}</td>
                  <td>{product.user.name}</td>
                  <td>
                    <Link
                      to={`/products/edit/${product.uuid}`}
                      className="button is-small is-info">
                      Edit
                    </Link>
                    {user && user.role === "admin" && (
                      <button
                        onClick={() => confirmDeleteProduct(product.uuid)}
                        className="button is-small is-danger">
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav className="pagination" role="navigation" aria-label="pagination">
          <button
            className="pagination-previous"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}>
            Previous
          </button>
          <button
            className="pagination-next"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}>
            Next page
          </button>
          <ul className="pagination-list">
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index}>
                <button
                  className={`pagination-link ${
                    currentPage === index + 1 ? "is-current" : ""
                  }`}
                  aria-label={`Goto page ${index + 1}`}
                  onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default ProductList;
