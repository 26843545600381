import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IoPerson, IoPricetag, IoHome, IoLogOut } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { LogOut, reset } from "../features/authSlice";
import Image from "../logo.png";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <aside className="aside is-placed-left is-expanded">
      <div className="aside-tools">
        <div className="aside-tools-label">
          <img src={Image} alt="logo" className="logo-sidebar" />
          <span>
            <b>Favori Corp</b>
          </span>
        </div>
      </div>
      <div className="menu is-menu-main">
        <p className="menu-label">General</p>
        <ul className="menu-list">
          <li>
            <NavLink
              to="/"
              className={
                location.pathname.startsWith("/dashboard")
                  ? "is-active router-link-active has-icon"
                  : "has-icon"
              }
              activeClassName="is-active">
              <span className="icon">
                <IoHome />
              </span>
              <span className="menu-item-label">Dashboard</span>
            </NavLink>
          </li>
        </ul>
        <p className="menu-label">Master Data</p>
        <ul className="menu-list">
          <li>
            <NavLink
              to={"/products"}
              className={
                location.pathname.startsWith("/products")
                  ? "is-active router-link-active has-icon"
                  : "has-icon"
              }
              activeClassName="is-active">
              <span className="icon">
                <IoPricetag />
              </span>
              <span className="menu-item-label">Products</span>
            </NavLink>
          </li>
          {user && user.role === "admin" && (
            <li>
              <NavLink
                to={"/customers"}
                className={
                  location.pathname.startsWith("/customers")
                    ? "is-active router-link-active has-icon"
                    : "has-icon"
                }
                activeClassName="is-active">
                <span className="icon">
                  <IoPerson />
                </span>
                <span className="menu-item-label">Customers</span>
              </NavLink>
            </li>
          )}
        </ul>
        <p className="menu-label">Menu</p>
        <ul className="menu-list">
          <li>
            <NavLink
              to={"/complaints"}
              className={
                location.pathname.startsWith("/complaints")
                  ? "is-active router-link-active has-icon"
                  : "has-icon"
              }
              activeClassName="is-active">
              <span className="icon">
                <IoPricetag />
              </span>
              <span className="menu-item-label">Complaints</span>
            </NavLink>
          </li>
        </ul>
        <p className="menu-label">Admin</p>
        <ul className="menu-list">
          {user && user.role === "admin" && (
            <li>
              <NavLink
                to={"/users"}
                className={
                  location.pathname.startsWith("/users")
                    ? "is-active router-link-active has-icon"
                    : "has-icon"
                }
                activeClassName="is-active">
                <span className="icon">
                  <IoPerson />
                </span>
                <span className="menu-item-label">Users</span>
              </NavLink>
            </li>
          )}
          <li>
            <a
              href=""
              className="navbar-item is-desktop-icon-only"
              onClick={logout}>
              <span className="icon">
                <IoLogOut></IoLogOut>
              </span>
              <span>Log out</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
