import React from "react";

const TitleBar = ({ titles }) => {
  return (
    <section className="section is-title-bar">
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <ul>
              {titles.map((title, index) => (
                <li key={index}>{title}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TitleBar;
