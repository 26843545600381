import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TitleBar from "./TitleBar";
import HeroBar from "./HeroBar";
import { fetchCustomersCount } from "../api/apiCustomer";
import { fetchProductsCount } from "../api/apiProduct";
import { fetchComplaintsCount } from "../api/apiComplaint";

const Welcome = () => {
  const { user } = useSelector((state) => state.auth);
  const [customersCount, setCustomersCount] = useState(0);
  const [productsCount, setProductsCount] = useState(0);
  const [complaintsCount, setComplaintsCount] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const customers = await fetchCustomersCount();
      const products = await fetchProductsCount();
      const complaints = await fetchComplaintsCount();

      setCustomersCount(customers);
      setProductsCount(products);
      setComplaintsCount(complaints);
    };

    fetchData();
  }, []);
  return (
    <>
      <TitleBar titles={["Admin", "Dashboard"]} />
      <HeroBar title="Dashboard" />
      <section className="section is-main-section">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <div className="level is-mobile">
                  <div className="level-item">
                    <div className="is-widget-label">
                      <h3 className="subtitle is-spaced">Customers</h3>
                      <h1 className="title">{customersCount}</h1>
                    </div>
                  </div>
                  <div className="level-item has-widget-icon">
                    <div className="is-widget-icon">
                      <span className="icon has-text-primary is-large">
                        <i className="mdi mdi-account-multiple mdi-48px"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <div className="level is-mobile">
                  <div className="level-item">
                    <div className="is-widget-label">
                      <h3 className="subtitle is-spaced">Products</h3>
                      <h1 className="title">{productsCount}</h1>
                    </div>
                  </div>
                  <div className="level-item has-widget-icon">
                    <div className="is-widget-icon">
                      <span className="icon has-text-info is-large">
                        <span class="mdi mdi-tag mdi-48px"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <div className="level is-mobile">
                  <div className="level-item">
                    <div className="is-widget-label">
                      <h3 className="subtitle is-spaced">Complain</h3>
                      <h1 className="title">{complaintsCount}</h1>
                    </div>
                  </div>
                  <div className="level-item has-widget-icon">
                    <div className="is-widget-icon">
                      <span className="icon has-text-danger is-large">
                        <span class="mdi mdi-alert mdi-48px"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Welcome;
