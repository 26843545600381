import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchComplaintsCount = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/complaint`);
    return response.data.total_count;
  } catch (error) {
    console.error("Error fetching complaints count:", error);
    return 0;
  }
};

export const getComplaints = async (page) => {
  try {
    const response = await axios.get(`${baseApiUrl}/complaint?page=${page}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching complaints:", error);
    return { complaints: [], total_pages: 1 };
  }
};

export const deleteComplaint = async (complaintId) => {
  try {
    await axios.delete(`${baseApiUrl}/complaint/${complaintId}`);
    return true;
  } catch (error) {
    console.error("Error deleting complaint:", error);
    return false;
  }
};

// export const saveComplaintWithItem = async (formData) => {
//   try {
//     await axios.post(`${baseApiUrl}/complaintwithitem`, formData, {
//       headers: { "Content-Type": "multipart/form-data" },
//     });
//     return true;
//   } catch (error) {
//     console.error("Error saving complaint:", error);
//     throw error;
//   }
// };

export const saveComplaintWithItem = async (data) => {
  try {
    const response = await axios.post(`${baseApiUrl}/complaintwithitem`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error saving complaint:", error);
    throw error;
  }
};
